/**********************************************************
 * Criador: Thiago Feijó                                  *
 * Data: 27/12/2017                                       *
 * *******************************************************/

interface Array<T> {
  isEmpty(): boolean;
  ordenar(chave: string): Array<T>;
}

/**
 * Retorna true se o array for vazio
 */
Array.prototype.isEmpty = function() {
  return !!(this.length < 1);
};

/**
 * Adiciona um ou mais itens
 */
Array.prototype.ordenar = function(chave: string): Array<any> {
  let array = (this as Array<any>)
  
  return array.sort(function(a, b) {
    let valueA = a[chave].toLowerCase();
    let valueB = b[chave].toLowerCase();

    let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }


    return comparatorResult;
  });

};
